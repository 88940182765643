import React from 'react';
import Layout from '../components/layout';
import DesignBuild from '../components/designBuild';
import Seo from '../components/seo';

export default function DesignBuildPage() {
  return (
    <Layout>
      <Seo
        title="Interior Design and Build"
        description="Personalized Process: From Initial Consultation to Seamless Construction, We Bring Your Dream Home to Life."
      />
      <DesignBuild />
    </Layout>
  );
}
