import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export default function DesignBuild() {
  return (
    <div className="bg-white">
      <div className="page-template">
        <div className=" flex w-full flex-col pr-0 md:w-8/12 lg:w-9/12 lg:pr-12">
          <h1 className="page-title">THE PROCESS</h1>
          <p className="mt-4 text-xl text-primary">
            Depending on the package you select, it starts with an Initial
            Consultation and one of the most essential steps of all –{' '}
            <span className="font-bold">LISTENING!</span>
          </p>
          <p className="mt-4 text-xl text-primary">
            Before construction can begin, a good plan or clear vision of what
            needs to transpire over the duration of the project must be
            developed. All decisions are made ahead of time. In order for this
            to be achieved, it’s essential that we get to know our clients and
            connect with them in order to gain a clear understanding of their
            style, needs and lifestyle. We ensure you remain an integral part of
            the design process throughout the duration of the project, and we
            utilize presentation tools that allow our clients to have a clear
            vision of the design concept early on in the project, saving time
            and money!
          </p>
          <h2 className="mt-8 text-xl font-bold tracking-widest text-primary sm:text-2xl">
            WE KNOW CONSTRUCTION
          </h2>
          <p className="mt-4 text-xl text-primary">
            Once the design details are in place, the next step is the build-out
            to make your dream a reality. The design process doesn't stop after
            the plans are complete. There is real value having an interior
            design professional on board who understands the construction
            process. We have worked with numerous contractors and have the
            ability to forecast and prevent costly mistakes. We have the
            experience and confidence to meet with those onsite and ensure your
            project is seamless.
          </p>
          <h2 className="mt-8 text-xl font-bold tracking-widest text-primary sm:text-2xl">
            PRAISES
          </h2>
          <p className="mt-4 text-xl text-primary">
            Steele Design Co. projects have been featured in Lifestyle &
            Neighbours of Riverbend magazine and more recently, on the cover of
            Lifestyle Magazine. In 2021, one of our kitchen designs won Best
            Renovated Kitchen over $60,000 in the 2021 Awards of Creative
            Excellence.
          </p>
        </div>
        <div className=" mx-auto mt-10 flex w-1/2 items-center justify-center md:mt-0 md:w-6/12 lg:w-3/12 xl:w-3/12">
          <div className="relative">
            <StaticImage src="../images/design-process.png" loading="eager" />
          </div>
        </div>
      </div>
    </div>
  );
}
